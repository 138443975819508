














import Vue from 'vue';
import Component from 'vue-class-component';
import Dialog from '@/components/ui/Dialog.vue';
@Component({
  components: {Dialog},
})
export default class UserCreateDialog extends Vue {}
