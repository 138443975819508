




















import Vue from 'vue';
import Component from 'vue-class-component';
import UserList from '@/components/users/UserList.vue';
import UserCreateDialog from '@/components/users/UserCreateDialog.vue';
import {Snackbar} from '@/interfaces';
import PageTitle from '@/components/ui/PageTitle.vue';
import NewButton from '@/components/ui/NewButton.vue';
import Page from '@/components/ui/Page.vue';

@Component({
  components: {Page, NewButton, PageTitle, UserList, UserCreateDialog},
})
export default class Users extends Vue {
  private snackbar: Snackbar = {visible: false, message: ''};
  private userDialogShown = false;

  showUserDialog() {
    this.userDialogShown = true;
  }

  closeUserDialog(message?: string) {
    this.userDialogShown = false;
    if (message) {
      this.snackbar.visible = true;
      this.snackbar.message = message;
    }
  }
}
