












































import Vue from 'vue';
import Component from 'vue-class-component';
import {userModule} from '@/store/modules';
import {Snackbar, UserAttributes, UserResource} from '@/interfaces';
import ButtonEditAction from '@/components/ui/ButtonEditAction.vue';
import ButtonDeleteAction from '@/components/ui/ButtonDeleteAction.vue';
import UserEditDialog from '@/components/users/UserEditDialog.vue';
import DateUserCell from '@/components/ui/DateUserCell.vue';

@Component({
  components: {
    DateUserCell,
    UserEditDialog,
    ButtonDeleteAction,
    ButtonEditAction,
  },
})
export default class UserList extends Vue {
  private headers = [
    {
      text: 'First Name',
      align: 'start',
      value: 'firstName',
    },
    {text: 'Last Name', value: 'lastName'},
    {text: 'Email', value: 'email'},
    {text: 'Status', value: 'googleUserId'},
    {text: 'Created', value: 'created'},
    {text: 'Modified', value: 'modified'},
    {text: 'Actions', value: 'actions'},
  ];
  private search = '';
  private snackbar: Snackbar = {visible: false, message: ''};
  private userId: null | string = null;
  private userDialogShown = false;

  get busy(): boolean {
    return userModule.busy;
  }

  get deleting(): boolean {
    return userModule.deleting;
  }

  get loading(): boolean {
    return userModule.loading;
  }

  get saving(): boolean {
    return userModule.saving;
  }

  get userList(): UserAttributes[] {
    return userModule.list.map(user => user.attributes);
  }

  async created(): Promise<void> {
    try {
      await userModule.loadMulti();
    } catch (err) {
      console.error(err.response.data);
    }
  }

  showUserDialog(userId?: string): void {
    this.userId = userId === undefined ? null : userId;
    this.userDialogShown = true;
  }

  closeUserDialog(message?: string): void {
    this.userDialogShown = false;
    if (message !== undefined) {
      this.snackbar.visible = true;
      this.snackbar.message = message;
    }
  }

  async deleteUser(userId: string): Promise<void> {
    this.userId = userId;
    const user = userModule.find(userId);
    const result = confirm(
      `Are you sure you want to delete the user ${user.attributes.firstName} ${user.attributes.lastName}?`
    );
    if (result) {
      await userModule.delete(userId);
    }
  }

  view(item: UserResource): void {
    this.showUserDialog(item.id);
  }
}
