































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  AlertMessage,
  SelectItem,
  UserGroupResource,
  UserResource,
} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import {userModule, userGroupModule} from '@/store/modules';
import Dialog from '@/components/ui/Dialog.vue';
import DialogAlert from '@/components/ui/DialogAlert.vue';
@Component({
  components: {DialogAlert, Dialog},
})
export default class UserEditDialog extends Vue {
  @Prop() userId!: string;
  private alert: AlertMessage | null = null;

  get user(): UserResource {
    return userModule.find(this.userId);
    // if (user) {
    //   console.log(user.attributes.userGroups.map(userGroup => userGroup.id));
    // }
    // return user;
  }

  get loading(): boolean {
    return userModule.loading;
  }

  get saving(): boolean {
    return userModule.saving;
  }

  get loadingUserGroups(): boolean {
    return userGroupModule.loading;
  }

  get userGroups(): SelectItem[] {
    return userGroupModule.list.length > 0
      ? userGroupModule.list.map((userGroup: UserGroupResource) => {
          return {
            text: userGroup.attributes.label,
            value: userGroup.attributes.id,
          };
        })
      : [];
  }

  async mounted() {
    try {
      console.log(this.userId);
      await userModule.loadById(this.userId);
      await userGroupModule.loadMulti();
    } catch (err) {
      console.error(
        `There was an error loading user ${this.userId} or getting User groups`
      );
    }
  }

  async save() {
    try {
      await userModule.save(this.userId);
      this.$emit('close', 'User added successfully');
    } catch (err) {
      this.alert = {message: 'Could not save user', type: 'error'};
    }
  }
  cancel() {
    this.$emit('close');
  }
}
