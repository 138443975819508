var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-text-field',{attrs:{"solo":"","id":"search","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.userList,"search":_vm.search,"loading":_vm.loading},on:{"click:row":_vm.view},scopedSlots:_vm._u([{key:"item.googleUserId",fn:function(ref){
var item = ref.item;
return [(item.googleUserId)?_c('v-chip',{attrs:{"color":"success"}},[_vm._v("Active")]):_vm._e(),(!item.googleUserId)?_c('v-chip',[_vm._v("Invited")]):_vm._e()]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('DateUserCell',{attrs:{"action":"created","date":item.created}})]}},{key:"item.modified",fn:function(ref){
var item = ref.item;
return [_c('DateUserCell',{attrs:{"action":"modified","date":item.modified}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center text-no-wrap"},[_c('ButtonEditAction',{attrs:{"loading":_vm.saving && _vm.userId === item.id,"disabled":_vm.busy && _vm.userId === item.id},on:{"click":function($event){return _vm.showUserDialog(item.id)}}}),_c('ButtonDeleteAction',{attrs:{"loading":_vm.deleting && _vm.userId === item.id,"disabled":_vm.busy && _vm.userId === item.id},on:{"click":function($event){return _vm.deleteUser(item.id)}}})],1)]}}])}),(_vm.userDialogShown)?_c('UserEditDialog',{attrs:{"userId":_vm.userId},on:{"close":function (message) { return _vm.closeUserDialog(message); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }